<template>
  <b-modal
    ref="choiceDialog"
    id="modal-tall"
    size="lg"
    title="International Shipment"
    hide-footer
    okOnly
    v-model="isOpen"
    centered
    modal-class="pricing-modal"
  >
    <template v-slot:modal-header="{ close }">
      <span class="d-block fs18 lh28 fw500 mt-8">
        Choose Carrier for Order ID - {{ form.orderId }}
      </span>
      <!-- Emulate built in modal header close button action -->
      <b-button size="sm" variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>

    <template v-slot:default>
    <div class="row justify-content-between pt-8 pt-md-26 pb-22">
      <div class="col-md-auto col-4">
        <span class="fs12 lh16 text-gray-600">
          Pickup
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.originCity + ' - ' + dialogHeader.originPin }}
        </span>
      </div>

      <div class="col-md-auto col-4">
        <span class="fs12 lh16">
          {{ dialogHeader.orderType.toUpperCase() }}
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          <img src="@/assets/svg/arrow-right.svg" alt="" />
        </span>
      </div>

      <div class="col-md-auto col-4">
        <span class="fs12 lh16 text-gray-600">
          Delivery
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ ellipsize(dialogHeader.customerCity) + ' - ' + dialogHeader.customerPinCode }}
        </span>
      </div>

      <div class="col-md-auto col-4 pt-28 pt-md-0">
        <span class="fs12 lh16 text-gray-600">
          Total Value
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          ₹ {{ parseFloat(dialogHeader.totalValue).toFixed(2) }}
        </span>
      </div>

      <div class="col-md-auto col-4 pt-28 pt-md-0" v-if="!dialogHeader.numberOfCarton">
        <span class="fs12 lh16 text-gray-600">
          Package Weight
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ parseFloat(dialogHeader.packageWeight).toFixed(2) }} Kg
        </span>
      </div>

      <!-- Only for MPS order -->
      <div class="col-md-auto col-4 pt-28 pt-md-0" v-if="dialogHeader.numberOfCarton">
        <span class="fs12 lh16 text-gray-600">
          Package Weight
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
           {{ parseFloat(dialogHeader.packageWeight).toFixed(2) }} Kg
        </span>
      </div>


      <div class="col-md-auto col-12 pt-28 pt-md-0" v-if="!dialogHeader.numberOfCarton && dialogHeader.channel != 37 ">
        <span class="fs12 lh16 text-gray-600">
          Package Dimensions
          <i
            class="icon-disclaimer fs12 text-gray-400"
            v-b-tooltip.hover.right
            title="Dim in LxWxH"
          ></i>
        </span>
<!--         <span v-if="choiceShipmentData && choiceShipmentData.items.length>1" class="fs14 lh20 text-gray-900 mt-4 d-block">
          Multiple
        </span> -->
        <!-- <span v-else class="fs14 lh20 text-gray-900 mt-4 d-block"> -->
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.packageLength }}cm x 
          {{ dialogHeader.packageWidth }}cm x 
          {{ dialogHeader.packageHeight }}cm
        </span>
      </div>

      <div class="col-md-auto col-12 pt-28 pt-md-0" v-if="dialogHeader.channel == 37">
        <span class="fs12 lh16 text-gray-600">
          Identical Boxes
          <i
            class="icon-disclaimer fs12 text-gray-400"
            v-b-tooltip.hover.right
            title="Total identical boxes!"
          ></i>
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
          {{ dialogHeader.identical }}
        </span>
      </div>

      <!-- Only for MPS order -->
      <div class="col-md-auto col-4 pt-28 pt-md-0" v-if="dialogHeader.channel != 37 && dialogHeader.numberOfCarton">
        <span class="fs12 lh16 text-gray-600">
          Carton Count
        </span>
        <span class="fs14 lh20 text-gray-900 mt-4 d-block">
           {{ dialogHeader.numberOfCarton }}
        </span>
      </div>

    </div>

    <div class="row pt-6 pt-md-22">
      <div class="col">
        <b-table
          :fields="fields"
          :tbody-tr-class="tbodyRowClass"
          responsive
          :items="rowData"
          class="package-details-table"
          select-mode="single"
        >
          <!-- A custom formatted column -->
          <template v-slot:cell(carrier)="data">
            <img :src="getImgUrl(data.item.carrier)" class="mw100 carrier-logo">
          </template>

          <!-- A custom formatted column -->
          <template v-slot:cell(min_billing_weight)="data">
            Min. Weight: {{ data.item.minweight }} kg
          </template>

          <template v-slot:cell(shipping_mode)="data">
            {{ data.item.mode }}
          </template>

          <template v-slot:cell(estimated_delivery)="data">
            {{ data.item.tat }} Days
          </template>

          <!-- A custom formatted column -->
          <template v-slot:cell(estimated_price)="data">
            ₹{{ dialogHeader && dialogHeader.channel == 37 ? data.item.price + ' + GST' : data.item.price }}
          </template>

          <template v-slot:cell(book_button)="data">
            <div style="width:max-content" v-if="(data.item.price > $store.state.global.overview.due) && $store.state.user.prepaid == 1">
              <b-link @click="openAddfunds" class="btn btn-primary" role="button">Add Funds</b-link>
               <i
                  class="icon-disclaimer ml-10 fs16 text-red"
                  v-b-tooltip.hover.top
                  title="Low Balance"
                ></i>
            </div>
            <div v-else>
              <b-link :disabled="data.item.disabled" v-if="dialogHeader.channel != 37" @click="bookButton(data.item.mode, data.item.carrier, data.item.price)" class="btn btn-primary" role="button">Book</b-link>
              <b-link :disabled="data.item.disabled" v-if="dialogHeader.channel == 37" @click="b2bBookButton(data.item.mode, data.item.carrier, data.item.price)" class="btn btn-primary" role="button">Book</b-link>
            </div>
            
          </template>
        </b-table>
        <div v-if="rowData.length == 0" class="text-red text-center">
          No service found for these pincodes.
        </div>
      </div>
      <BillingAddFunds ref="BillingAddFunds" />
    </div>
    </template>

  </b-modal>
</template>

<script>
import BillingAddFunds from "@/components/BillingAddFunds.vue";


export default {
  name: "choiceDialog",
  props: ['modalOpen', 'formInfo', 'choiceData', 'bookOrderFlag', 'choiceShipmentData'],
  components: {
    BillingAddFunds
  },
  data() {
    return {
      selectedRow: null,
      isOpen: this.modalOpen,
      fields: [
        {
          key: "carrier",
          label: "Carrier",
          sortable: true,
          thClass: "pl-4",
          tdClass: "pl-4",
        },
        {
          key: "min_billing_weight",
          label: "Min. Billing Weight",
          thClass: "wsnw",
        },
        {
          key: "shipping_mode",
          label: "Shipping Mode",
          sortable: true,
          thClass: "wsnw",
          tdClass: "wsnw",
        },
        {
          key: "estimated_delivery",
          label: "Est. Delivery",
          sortable: true,
          thClass: "wsnw",
        },
        {
          key: "estimated_price",
          label: "Est. Price",
          sortable: true,
          thClass: "wsnw",
        },
        {
          key: "book_button",
          label: "",
          sortable: true,
          thClass: "wsnw",
        },
      ],

      details: {
        transit_time: "5-10 days",
        pod: "On request, POD will be provided",
        max_liability: "USD 100",
        first_500: 1380,
        add_500: 400,
      },

      dialogHeader: {},
      rowData: [],
      form: {},
    };
  },

    watch: {

        formInfo: {
            handler(newValue) {
                if(newValue.originPin) {
                  this.dialogData(newValue)
              }
            },
            deep: true 
        },

        choiceData: {
          handler(value) {
            if (value && value.length) {
              if(Object.keys(value).length > 0) {
                this.choiceDialogData(value)
              }
            }else{
              this.rowData = []
            }
          },
          deep: true
        }

    },
  methods: {
    openAddfunds() {
      this.$refs.BillingAddFunds.isOpen = !this.$refs.BillingAddFunds.isOpen;
    },

    dialogData(form) {
      
        this.dialogHeader.originPin = form.originPin;
        this.dialogHeader.originCity = form.originCity;
        this.dialogHeader.orderType = form.orderType;

        this.dialogHeader.customerPinCode = form.customerPinCode;
        this.dialogHeader.customerCity = form.customerCity;

        this.dialogHeader.totalValue = form.totalValue;
        this.dialogHeader.packageWeight = form.packetdiamention && form.packetdiamention[0].itemWeight ? form.packetdiamention[0].itemWeight : form.packageWeight;

        this.dialogHeader.packageLength = form.packetdiamention && form.packetdiamention[0].itemLength ? form.packetdiamention[0].itemLength : form.packageLength;

        this.dialogHeader.packageWidth = form.packetdiamention && form.packetdiamention[0].itemWidth ? form.packetdiamention[0].itemWidth : form.packageWidth;

        this.dialogHeader.packageHeight = form.packetdiamention && form.packetdiamention[0].itemHeight ? form.packetdiamention[0].itemHeight : form.packageHeight;

        this.dialogHeader.numberOfCarton = form.identical;

        this.dialogHeader.channel = form.channel;

        if(this.dialogHeader.channel == 37) {
          // let identicalTotal = 0;
          // for (let i = 0; i < form.identical.length; i++) {
          //     identicalTotal += Number(form.identical[i]);
          // }

          // let packageWeightTotal = 0;
          // for (let i = 0; i < form.packageWeight.length; i++) {
          //     packageWeightTotal += Number(form.packageWeight[i]);
          // }

          this.dialogHeader.packageWeight = form.packageWeight 
          this.dialogHeader.identical = form.identical 

        }

        this.form = form;

    },
    choiceDialogData(value) {

        let disableServiceArray = new Array();
        let enabledServiceArray = new Array();

        if(value == 'B') {
          return false;
        }

        value.map(value => {
            if(value.length){
                value.map(val => {

                    if(val.disabled)
                        disableServiceArray.push(val);
                    else if (val.price == null || val.price == 0)
                        return false
                    else
                        enabledServiceArray.push(val);
                })
            }
        })

        const completeArray = enabledServiceArray.concat(disableServiceArray)

        let valueArray = completeArray.filter( val => !isNaN(parseFloat(val.price)))

        const sorter = (a, b) => {
            return +a.price - +b.price;
        };

        let sortedPriceChart = valueArray.sort(sorter);

        this.rowData = sortedPriceChart.map((i) => {
            return {

                carrier: i.carrier || '',
                minweight: i.minweight,
                mode: this.staticName(this.$store.getters.modes, i.mode),
                price: i.price,
                service: i.service,
                serviceCode: i.serviceCode,
                tat: i.tat,
                disabled: i.disabled == true ? true : false,
            }
        })

    },
    staticName(statics, index) {
      return statics.filter(i => i.value == index)[0].name
    },

    staticValue(statics, name) {
      return statics.filter(i => i.name == name)[0].value
    },

    async bookButton(mode, carrier, price) {

        this.form.modeType = this.staticValue(this.$store.getters.modes, mode)

        this.form.selectedCarrier = carrier

        if (this.bookOrderFlag) {
            this.form.orederID = this.form.orderID;
            this.form.processSaved = true; 
        }

        if (this.dialogHeader.numberOfCarton) {

            this.form.estimatedCharges = price;

            let data = Object.assign({}, this.form);

            data.channel = 'MPS';
            data.numberOfCarton = data.noc_mps;
            data.identical = 0;

            data.packageWeight = data.packageWeightArr

            delete data.noc_mps

            try {

                let result = await this.axios.post('/orders/mps', data);

                if (result.data.success == true && result.data.message) {
                    this.popToast("booked", "Success!", result.data.message);

                    this.isOpen = false
                    
                    setTimeout(() => { 
                      this.$router.push("/shipments?page=1&offset=25")
                    }, 1000)
                    
                }
                if(result.data.success == false && result.data.message) {
                    this.popToast("failed", "Failed!", result.data.message.response[0].error);
                }
                if(result.data.success == false && result.data.error) {
                    this.popToast("failed", "Failed!", result.data.error.response[0].error);
                }

            }
            catch(error) {
                console.log(error);
            }

        }
        else if (this.form.tableID && this.form.bookReverse == true) {

            this.choiceShipmentData.modeType = this.staticValue(this.$store.getters.modes, mode)

            this.choiceShipmentData.selectedCarrier = carrier

            this.choiceShipmentData.channel = "SINGLE"

            this.form.orderDate = new Date(this.form.orderDate).toLocaleDateString(['zu-ZA'], {year: "numeric",month: "2-digit",day: "2-digit"});

            try {

                this.disabled = true

                let result = await this.axios.post(`/shipment/book/${this.form.tableID}/1`, this.choiceShipmentData);

                this.isOpen = false;

                if (result.data.success == true && result.data.message) {
                    this.popToast("booked", "Success!", result.data.message);
                    this.isOpen = false
                    setTimeout(() => { 
                      this.$router.go(-1)
                    }, 1000)

                }
                if(result.data.success == false && result.data.message) {
                    this.popToast("failed", "Failed!", result.data.message);
                    setTimeout(() => { 
                        this.$router.push("/orders?page=1&offset=25")
                      }, 1000)
                }

                this.disabled = false
            }
            catch(err) {

            }
        }
        else if (this.form.tableID) {

            this.choiceShipmentData.modeType = this.staticValue(this.$store.getters.modes, mode)

            this.choiceShipmentData.selectedCarrier = carrier

            this.choiceShipmentData.channel = "SINGLE"

            let formData = this.choiceShipmentData

            this.form.orderDate = new Date(this.form.orderDate).toLocaleDateString(['zu-ZA'], {year: "numeric",month: "2-digit",day: "2-digit"});

            try {

                this.disabled = true

                let result = await this.axios.post(`/shipment/book/${this.form.tableID}/0`, this.choiceShipmentData);

                this.isOpen = false;

                if (result.data.success == true && result.data.message) {
                    this.popToast("booked", "Success!", result.data.message);
                    setTimeout(() => { 
                      this.$router.go(-1)
                    }, 1000)

                }
                if(result.data.success == false && result.data.message) {
                    this.popToast("failed", "Failed!", result.data.message);
                    setTimeout(() => { 
                        this.$router.push("/orders?page=1&offset=25")
                      }, 1000)
                }

                this.disabled = false
            }
            catch(err) {

            }
        }
        else {
          let data = {};

          if (this.form.processSaved == true) {
            Object.assign(data, {
                mode: String(this.form.modeType),
                orederID: String(this.form.id),
                selectedCarrier: this.form.selectedCarrier,
                processSaved: this.form.processSaved
            })

          }
          else {
              Object.assign(data, this.form);

              data.channel = "SINGLE";
              data.orderID = this.form.orderId;
          }

          try {

              let result = await this.axios.post('/choice/bookchoice', data);

              if (result.data && result.data.success == true) {
                  this.popToast("booked", "Success!", result.data.message);
                  this.isOpen = false
                  if(this.$route.path == "/new") {
                      setTimeout(() => { 
                        this.$router.push("/shipments?page=1&offset=25")
                      }, 1000)
                  }
                  else if(this.$store.state.global.bookfromorderTable == true){
                    this.$parent.orderPageData()
                  } else if(this.$store.state.global.bookfromorderdetails == true) {
                    setTimeout(() => { 
                        this.$router.go("/shipments?page=1&offset=25")
                      }, 1000)
                  }
              }
              if(result.data && result.data.success == false) {
                  this.popToast("failed", "Failed!", result.data.message);
                  if(this.$store.state.global.bookfromorderTable == true){
                    this.$parent.orderPageData()
                  } else if(this.$store.state.global.bookfromorderdetails == true) {
                    setTimeout(() => { 
                        this.$router.push("/orders?page=1&offset=25")
                      }, 1000)
                  }
              }
              this.isOpen = false;

          }
          catch(error) {
              console.log(error);
          }
        }

    },
    async b2bBookButton(mode, carrier, estimatedPrice) {

        this.form.modeType = this.staticValue(this.$store.getters.modes, mode)

        this.form.carrierCode = carrier;

        this.form.estimatedCharges = estimatedPrice;

        try {

            let result = await this.axios.post('/orders/b2b', this.form);

            if (result.data.message) {

              let responseMessage = result.data.message[0] && result.data.message[0].message ? result.data.message[0].message : '';

                if(responseMessage.includes("Your booking is disabled") || responseMessage.includes("totalValue must match the total of identical values") || responseMessage.includes("Order already Exist")) {
                    this.popToast("failed", "Failed!", result.data.message[0].message);
                } 
                else if (result.data.message.includes('greater than or equal to 1')) {
                    this.isOpen = false
                    this.popToast("failed", "Failed!", "Please make sure Value per Carton does not recede 1");
                }
                else {
                    if(result.data.success) {
                      this.popToast("booked", "Success!", result.data.message);

                      this.isOpen = false
                      setTimeout(() => { 
                        this.$router.push("/shipments?page=1&offset=25")
                      }, 1000)
                    } else {
                      this.popToast("failed", "Failed!", "Booking Failed!! Please Try Again");
                    }
                    
                }
            }
            if(result.data.error) {
                this.popToast("failed", "Failed!", result.data.error);
            }
        }
        catch(error) {
              console.log(error);
        }

    },

    tbodyRowClass(item) {
        if (item.selected) {
            return ["pricing-row-selected"];
        }
    },
    getImgUrl(carrier) {

      let images = require(`../assets/images/${carrier}.png`)
        
      return images
     
    },
    setValue: function(value) {
      console.table(value);
    },
  },
  created: function() {
    this.$parent.$on("openModal", this.setValue);
  },
};
</script>

<style scoped>
  .carrier-logo {
    max-height: 40px;
  }
</style>